import React from 'react';
import { StateProvider } from './stateContext';
import { AuthProvider } from './authContext';

export const wrapRootElement = ({ element }) => (
  <AuthProvider>
    <StateProvider>
      {element}
    </StateProvider>
  </AuthProvider>
);
