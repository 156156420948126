// FirebaseFunctions.js

import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, setPersistence, browserLocalPersistence } from 'firebase/auth';

const config = {
  apiKey: "AIzaSyBN2hnAac6evLNuQyK1UWHxHOHv78qQB2A",
  authDomain: "alli-app.firebaseapp.com",
  projectId: "alli-app",
  storageBucket: "alli-app.appspot.com",
  messagingSenderId: "570231682874",
  appId: "1:570231682874:web:73f05d09079ffa0e184203",
  measurementId: "G-MDB5ZX7DFL"
};

const app = initializeApp(config);
const auth = getAuth(app);

const signIn = async (email, password) => {
  try {
    // Set the persistence to LOCAL before signing in
    await setPersistence(auth, browserLocalPersistence);

    // Then proceed with signing in
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (err) {
    console.log('error', err);
    throw(err);
  }
}

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (err) {
    throw(err);
  }
}

export {
  signIn,
  sendPasswordReset,
  auth,
}