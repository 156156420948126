const initialState = {
  isBookingInfoSlider: false,
  isFileInfoSlider: false,
  isBookingSlider: false,
  isFileSlider: false,
  isShiftSlider: false,
  isDeleteShiftModal: false,
  isShift: false,
  isSuccess: false,
  isError: false,
  successMessage: '',
  errorMessage: '',
  shiftSelected: null,
  slotId: '',
  booking: {
  },
  file: {
  },
  fileId: '',
};

export default initialState;